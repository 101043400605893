<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="company-details-setting-wrapper">

          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">Default Company Details/List</h4>
          </div>

              <div class="accordion" id="companyAccordion">

                <div class="card">
                  <div class="card-header" id="settingDefaultCompanyNameHeading">
                    <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                            data-target="#settingDefaultCompanyNameCollapse" aria-expanded="false" aria-controls="collapseCompanyName">

                      <span class="text-capitalize company-details-setting-title">Company Name</span>

                    </button>
                  </div>
                  <div id="settingDefaultCompanyNameCollapse" class="collapse" aria-labelledby="settingDefaultCompanyNameHeading" data-parent="#companyAccordion">
                    <div class="card-body">
                      <SettingDefaultCompanyName :setting-default-company-name="this.settingDefaultCompanyName"/>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="settingDefaultCompanyABNHeading">
                    <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                            data-target="#settingDefaultCompanyABNCollapse" aria-expanded="false" aria-controls="collapseCompanyABN">

                      <span class="text-capitalize company-details-setting-title">Company ABN</span>

                    </button>
                  </div>
                  <div id="settingDefaultCompanyABNCollapse" class="collapse" aria-labelledby="settingDefaultCompanyABNHeading" data-parent="#companyAccordion">
                    <div class="card-body">
                      <SettingDefaultCompanyABN :setting-default-company-abn="this.settingDefaultCompanyAbn"/>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="settingDefaultContactNumberHeading">
                    <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                            data-target="#settingDefaultContactNumberCollapse" aria-expanded="false" aria-controls="collapseContactNumber">

                      <span class="text-capitalize company-details-setting-title">Contact Number</span>

                    </button>
                  </div>
                  <div id="settingDefaultContactNumberCollapse" class="collapse" aria-labelledby="settingDefaultContactNumberHeading" data-parent="#companyAccordion">
                    <div class="card-body">
                      <SettingDefaultContactNumber :default-contact-number="this.defaultContactNumber"/>
                      <SettingDefaultStateContactNumber v-if="this.defaultStateContactNumber?.value?.length > 0" :default-state-contact-number="this.defaultStateContactNumber"/>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="settingDefaultCompanyAddressHeading">
                    <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                            data-target="#settingDefaultCompanyAddressCollapse" aria-expanded="false" aria-controls="collapseCompanyAddress">

                      <span class="text-capitalize company-details-setting-title">Company Address</span>

                    </button>
                  </div>
                  <div id="settingDefaultCompanyAddressCollapse" class="collapse" aria-labelledby="settingDefaultCompanyAddressHeading" data-parent="#companyAccordion">
                    <div class="card-body">
                      <SettingDefaultCompanyAddress :default-company-address="this.defaultCompanyAddress"/>
                      <SettingDefaultStateCompanyAddress v-if="this.defaultStateCompanyAddress?.value?.length > 0" :default-state-company-address="this.defaultStateCompanyAddress"/>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="settingDefaultGstRateHeading">
                    <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                            data-target="#settingDefaultGstRateCollapse" aria-expanded="false" aria-controls="collapseDefaultGstRate">

                      <span class="text-capitalize company-details-setting-title"> Gst Rate</span>

                    </button>
                  </div>
                  <div id="settingDefaultGstRateCollapse" class="collapse" aria-labelledby="settingDefaultGstRateHeading" data-parent="#companyAccordion">
                    <div class="card-body">
                      <SettingDefaultGstRate :default-gst-rate="this.defaultGstRate"/>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="settingDefaultCustomerCareEmailAddressHeading">
                    <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                            data-target="#settingDefaultCustomerCareEmailAddressCollapse" aria-expanded="false" aria-controls="collapseDefaultCustomerCareEmailAddress">

                      <span class="text-capitalize company-details-setting-title">Customer Care Email Address</span>

                    </button>
                  </div>
                  <div id="settingDefaultCustomerCareEmailAddressCollapse" class="collapse" aria-labelledby="settingDefaultCustomerCareEmailAddressHeading" data-parent="#companyAccordion">
                    <div class="card-body">
                      <SettingDefaultCustomerCareEmailAddress :default-customer-care-email-address="this.defaultCustomerCareEmailAddress"/>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import SettingDefaultCompanyName from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultCompanyName';
import SettingDefaultCompanyABN from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultCompanyABN';
import SettingDefaultContactNumber from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultContactNumber';
import SettingDefaultStateContactNumber from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultStateContactNumber';
import SettingDefaultCompanyAddress from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultCompanyAddress';
import SettingDefaultStateCompanyAddress from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultStateCompanyAddress';
import SettingDefaultGstRate from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultGstRate';
import SettingDefaultCustomerCareEmailAddress from '@/views/backEnd/settings/default/companyDetails/includes/SettingDefaultCustomerCareEmailAddress';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SettingDefaultCompanyDetails.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingDefaultCompanyName,
    SettingDefaultCompanyABN,
    SettingDefaultContactNumber,
    SettingDefaultStateContactNumber,
    SettingDefaultCompanyAddress,
    SettingDefaultStateCompanyAddress,
    SettingDefaultGstRate,
    SettingDefaultCustomerCareEmailAddress,
  },
  data() {
    return {
      getSettingsParams: {
        type: ["default"],
        key: [
          'default_company_name',
          'default_company_abn',
          "default_gst_rate",
          "default_contact_number",
          "default_state_contact_number",
          "default_company_address",
          "default_state_company_address",
          "default_customer_care_email_address",
          "default_state"

        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      settingDefaultCompanyName: 'appSettings/settingDefaultCompanyName',
      settingDefaultCompanyAbn: 'appSettings/settingDefaultCompanyABN',
      defaultContactNumber: 'appSettings/settingDefaultContactNumber',
      defaultStateContactNumber: 'appSettings/settingDefaultStateContactNumber',
      defaultGstRate: 'appSettings/settingDefaultGstRate',
      defaultCompanyAddress: 'appSettings/settingDefaultCompanyAddress',
      defaultStateCompanyAddress: 'appSettings/settingDefaultStateCompanyAddress',
      defaultCustomerCareEmailAddress: 'appSettings/settingDefaultCustomerCareEmailAddress',
    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.company-details-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.company-details-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
