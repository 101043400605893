<template>
  <div>
      <div class="px-2">
        <div class="row">

          <div class="w-100" style="text-align: center">
            <h2>State Wise Company Address</h2>
          </div>
          <div class="w-100 mr-1">
            <div class="d-flex justify-content-end">
              <button :disabled="isAddButtonDisabled" class="btn btn-primary ml-2" type="submit" @click="addNewStateAddress()">Add New</button>
            </div>
          </div>

          <div v-for="(stateAddress,index) in stateList" :key="index" class="d-flex w-100 align-items-end mb-1">

            <div class="col-md-3">

              <div class="">
                <label>Select state</label>
                <VueMultiselect :allow-empty="false" v-model="stateAddress.selectedState" class="" :options="stateAddress?.availableStateList ?? []" :close-on-select="true" placeholder="Select State"
                                label="name" track-by="value" :show-labels="false"/>
              </div>
            </div>

            <div class="col-md-4">
              <label>Street</label>
              <input type="text" v-model="stateAddress.address.street" class="form-control" id="defaultStateCompanyStreet" placeholder="Default State Company Street">
            </div>

            <div class="col-md-2">
              <label>Suburb</label>
              <input type="text" v-model="stateAddress.address.suburb" class="form-control" id="defaultStateCompanySuburb" placeholder=" State Company Suburb">
            </div>


            <div class="col-md-1 mr-1">
              <label>Post Code</label>
              <input type="text" class="form-control" v-model="stateAddress.address.post_code" id="defaultStateCompanyPostCode" placeholder=" State Company Post Code">
            </div>

            <div class="w-100 mr-1">
              <label>Country</label>
              <input disabled type="text" class="form-control" v-model="stateAddress.address.country" id="defaultStateCompanyCountry" placeholder=" State Company Country">
            </div>

            <div>
              <button @click="removeStateAddress(stateAddress.id)" class="btn"><i class='bx bx-x'></i></button>
            </div>

          </div>

          <div class="d-flex w-100 align-items-center justify-content-end px-2">
            <div>
              <button @click="updateSettingsHandler" class="btn btn-primary " type="submit">Update</button>
            </div>

          </div>

        </div>
      </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "SettingDefaultStateCompanyAddress",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultStateCompanyAddress'],
  data() {
    return {
      currentSetting: this.defaultStateCompanyAddress,
      stateAddress: [],
      stateList: []
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      settingDefaultState: 'appSettings/settingDefaultState',

    }),
    isAddButtonDisabled() {
      return this.stateList.length === this.settingDefaultState.value.length;
    },
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),


    removeStateAddress(id) {
      const selectedAddress = this.stateList.find((singleAddress) => singleAddress.id === id);
      if (selectedAddress.selectedState?.value) {
        const isPreviousAllAddressHasSelectedState = this.stateList.every((singleAddress) => {
          if (singleAddress?.selectedState?.value) {
            return true;
          }
          return false;
        })
        if (isPreviousAllAddressHasSelectedState) {

          this.stateList = this.stateList.filter((singleAddress) => singleAddress.id !== id);

          this.stateList = this.stateList.map((singleAddress) => {
            return {
              ...singleAddress, availableStateList: [...singleAddress.availableStateList, selectedAddress.selectedState]
            }
          });
          return;
        }

        this.showToastMessage({type: 'error', message: 'Please select state of unselected state'})
      } else {
        this.stateList = this.stateList.filter((singleAddress) => singleAddress.id !== id);

        this.stateList = this.stateList.map((singleAddress) => {
          return {
            ...singleAddress, availableStateList: [...singleAddress.availableStateList]
          }
        });
      }


    },


    addNewStateAddress() {
      const isPreviousAllAddressHasSelectedState = this.stateList.every((singleAddress) => {
        if (singleAddress?.selectedState?.value) {
          return true;
        }
        return false;
      })
      if (isPreviousAllAddressHasSelectedState) {
        let availableStates = [];
        this.settingDefaultState.value.map((state) => {
          let foundedState = this.stateList.find((addressState) => addressState.selectedState.value === state.value);
          if (foundedState === undefined) {
            availableStates.push(state);
          }
        });
        this.stateList = this.stateList.map((singleAddress) => {
          return {
            ...singleAddress, availableStateList: [singleAddress.selectedState]
          }
        });

        this.stateList.push({
          state_code: "",
          address: {
            street: "",
            suburb: "",
            state: "",
            post_code: "",
            country: "AU",
          },
          id: this.stateList[this.stateList.length - 1].id + 1,
          selectedState: "",
          availableStateList: availableStates

        });
        return;
      }
      this.showToastMessage({type: 'error', message: 'Please select state of unselected state'})

    },


    async updateSettingsHandler() {
      const isAllStateSelected = this.stateList.every((singleAddress) => {
        if (singleAddress?.selectedState?.value) {
          return true;
        }
        return false;
      })
      if (!isAllStateSelected) {
        this.showToastMessage({type:'error',message:'Please select all states'});
        return;
      }


      const isAllStreetTyped = this.stateList.every((singleAddress) => {
        if (singleAddress?.address?.street) {
          return true;
        }
        return false;
      })
      if (!isAllStreetTyped) {
        this.showToastMessage({type:'error',message:'Please enter street name for all address'});
        return;
      }

      const isAllSuburbTyped = this.stateList.every((singleAddress) => {
        if (singleAddress?.address?.suburb) {
          return true;
        }
        return false;
      })
      if (!isAllSuburbTyped) {
        this.showToastMessage({type:'error',message:'Please enter suburb name for all address'});
        return;
      }

      const isAllPostCodeTyped = this.stateList.every((singleAddress) => {
        if (singleAddress?.address?.post_code) {
          return true;
        }
        return false;
      })
      if (!isAllPostCodeTyped) {
        this.showToastMessage({type:'error',message:'Please enter post code  for all address'});
        return;
      }


      const uniqueStates = new Map();
      this.stateList.map((singleAddress) => {
        uniqueStates.set(singleAddress.selectedState.value);
      })
      if (uniqueStates.size !== this.stateList.length){
        this.showToastMessage({type:'error',message:'Please select unique state for all addess'});
        return;
      }

      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.stateList.map((singleAddress)=> {
            return {
              state_code : singleAddress.selectedState.value,
              address: {
                street: singleAddress.address.street,
                suburb: singleAddress.address.suburb,
                state: singleAddress.selectedState.value,
                post_code: singleAddress.address.post_code,
                country: singleAddress.address.country,

              }
            }
          }),
        }
      };
      console.log(dataObj, "dataObj")
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'State company address updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });

    },
    getAvailableStateList() {
      let returnStateList = [];
      this.settingDefaultState.value.map((state) => {
        let foundedState = this.currentSetting.value.find((addressState) => addressState.state_code === state.value);
        if (foundedState === undefined) {
          returnStateList.push(state);
        }
      })
      return returnStateList;
    }
  },

  mounted() {
    this.stateList = this.currentSetting.value.map((singleAddress, index) => {
      const selectedState = this.settingDefaultState.value.find((state) => state.value === singleAddress.state_code);
      return {
        ...singleAddress,
        id: index,
        selectedState: {...selectedState},
        availableStateList: [selectedState, ...this.getAvailableStateList()]

      };
    });
  }
}
</script>

<style scoped>

</style>