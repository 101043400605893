<template>
  <div>
      <div class="px-2">

        <div class="row">
          <div class="w-100" style="text-align: center">
            <h2>Company Address</h2>
          </div>
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <label>Street</label>
              <input v-model="currentSetting.value.street" type="text" class="form-control" id="defaultCompanyStreet" placeholder="Company Street">
            </div>

            <div class="w-100 mr-1" >
              <label>Suburb</label>
              <input v-model="currentSetting.value.suburb" type="text" class="form-control" id="defaultCompanySuburb" placeholder="Company Suburb">
            </div>

            <div class="w-100 mr-1">
              <label>State</label>
              <input v-model="currentSetting.value.state" type="text" class="form-control" id="defaultCompanyState" placeholder="Company State">
            </div>

            <div class="w-100 mr-1" >
              <label>Post Code</label>
              <input v-model="currentSetting.value.post_code" type="text" class="form-control" id="defaultCompanyPostCode" placeholder="Company Post Code">
            </div>

            <div class="w-100 mr-1" >
              <label>Country</label>
              <input v-model="currentSetting.value.country" type="text" class="form-control" id="defaultCompanyCountry" placeholder="Company Country">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>
      </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "SettingDefaultCompanyAddress",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultCompanyAddress'],
  data() {
    return {
      currentSetting: this.defaultCompanyAddress,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    buildJsonValue() {
      let JsonObject = {
        street: this.currentSetting?.value?.street ?? "",
        suburb: this.currentSetting?.value?.suburb ?? "",
        state: this.currentSetting?.value?.state ?? "",
        post_code: this.currentSetting?.value?.post_code ?? "",
        country: this.currentSetting?.value?.country ?? "",
      };
      console.log(JsonObject)
      return JSON.stringify(JsonObject);
    },

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.buildJsonValue(),
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Address updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>